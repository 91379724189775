export const MAX_CHARACTER_LIMIT = 1000

export const roles = {
  Customer: 'customer',
  Healer: 'healer',
  Admin: 'admin',
  Vendor: 'vendor',
}

export const taxOnCustomer = 18

export const honorifics = ['Mr', 'Miss', 'Mrs', 'Ms', 'Mx']

export const genders = ['Male', 'Female', 'Other']

export const years = ['2021', '2022', '2023', '2024']

export const timeUnits = ['YEAR', 'MONTH', 'WEEK']

export const slots = [
  '00:00 - 02:00',
  '02:00 - 04:00',
  '04:00 - 06:00',
  '06:00 - 08:00',
  '08:00 - 10:00',
  '10:00 - 12:00',
  '12:00 - 14:00',
  '14:00 - 16:00',
  '16:00 - 18:00',
  '18:00 - 20:00',
  '20:00 - 22:00',
  '22:00 - 23:59',
]

export const maritalStatuses = [
  'Married',
  'Single',
  'Divorced',
  'Widow',
  'Separated',
]

export const education = [
  'No Formal Education',
  '10th Pass',
  '12th Pass',
  'Graduation',
  'Post Graduation Diploma',
  'Masters Degree',
  'Doctorate',
]

export const employmentStatus = [
  'Salaried - full time',
  'Salaried - part time',
  'Self Employed',
  'Unemployed',
  'Student',
]

export const consultationServices = [
  'In Person',
  'On Chat',
  'On Video call',
  'On Voice call',
]

export const platforms = [
  { name: 'Astrotalk' },
  { name: 'Astrosage' },
  { name: 'Astrobuddy' },
  { name: 'Astroyogi' },
  { name: 'Ganeshaspeaks' },
  { name: 'horoscope.com' },
  { name: 'Instaastro' },
]

export const ImageFilesType = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/heic',
]

export const paidChatEndReasons = [
  'Customer abusive/rude/impolite',
  'Privacy policy violation',
  'Customer asking personal information',
  'Emergency situation',
  'Technical reasons (bad internet, power cut off)',
  'Language issues',
  'Something else (I will mention below)',
]

export const paidChatEndReasonsCustomers = [
  'Healer abusive/rude/impolite',
  'Privacy policy violation',
  'Healer asking personal information',
  'Emergency situation',
  'Technical reasons (bad internet, power cut off)',
  'Language issues',
  'Something else (I will mention below)',
]

export const rejectReasons = [
  'It is a spam',
  'False information',
  'Scam or fraud',
  'Intellectual property violantion',
  'Violence or dangerous organization',
  'Something else (I will mention below)',
]

export const blockCustomerReasons = [
  'Fraudulent Customer',
  'Abusive/disrespectful behaviour',
  '>5 negative ratings',
  'Privacy Policy Violation',
  'Something else (I will mention below)',
]

export const blockVendorReasons = [
  'Fraudulent vendor',
  'False claims of benefits',
  '>3 Instances of policy violation',
  '>3 Orders disputes',
  'Inappropriate behaviour to customer (Rude | Impolite | Misbehaviour | Misdemeanour | Disrespectful)',
  'Something else (I will mention below)',
]

export const blockHealerReasons = [
  'Fraudulent healer',
  'False claims of benefits',
  '>3 Instances of policy violation',
  '>3 Group services cancelled',
  '>3 Orders disputes',
  'Inappropriate behaviour to customer (Rude | Impolite | Misbehaviour | Misdemeanour | Disrespectful)',
  'Something else (I will mention below)',
]

export const rejectProductsServiceGroupservice = [
  'Content not as per policy',
  'Picture not as per policy',
  'Title not as per policy',
  'Pricing is not as per policy',
  'Details and Benefits are not clear',
  'Something else (I will mention below)',
]

export const deactivateReasons = [
  'Fraudulent product/service/group service',
  'False claims of benefits',
  '>10 negative reviews by customer',
  'No longer offered',
  'Product/Service/Group service not as per the policy (tantric, black magic etc)',
  'Something else (I will mention below)',
]

export const callEndReasons = [
  'Customer behaviour inappropriate',
  'Privacy policy violation',
  'Customer asking for personal information',
  'Emergency situation',
  'Technical issues (bad network, power issues, system issues etc)',
  'Language issues',
  'Something else (I will mention below)',
]

export const serviceEndReasons = [
  'Service completed',
  'Emergency situation',
  'Attandee not joined',
  'Pre-Service consultation call',
  'Technical issues (bad network, power issues, system issues etc)',
  'Language issues',
  'Customer behaviour inappropriate',
  'Something else (I will mention below)',
]

export const poojaTypes = {
  individualPooja: 'individualPooja',
  withFamily: 'withFamily',
}

export const messageTypes = {
  TEXT: 'text',
  IMAGE: 'images',
  VOICE: 'voice',
  VIDEO_CALL: 'video_call',
  AUDIO_CALL: 'audio_call',
  CHAT_ENDED_LOW_BALANCE: 'chat_ended_low_balance',
  CHAT_ENDED_BY_HEALER: 'chat_ended_by_healer',
  CHAT_ENDED_BY_CUSTOMER: 'chat_ended_by_customer',
  CHAT_RESTARTED: 'chat_restarted',
  NOT_AVAILABLE_FOR_AUDIO_CALL: 'not_available_for_audio_call',
  NOT_AVAILABLE_FOR_VIDEO_CALL: 'not_available_for_video_call',
  VIDEO_CALL_REJECTED: 'video_call_rejected',
  AUDIO_CALL_REJECTED: 'audio_call_rejected',
  VIDEO_CALL_REJECTED_BY_HEALER: 'video_call_rejected_by_healer',
  VIDEO_CALL_REJECTED_BY_CUSTOMER: 'video_call_rejected_by_customer',
  AUDIO_CALL_REJECTED_BY_HEALER: 'audio_call_rejected_by_healer',
  VIDEO_CALL_REJECTED_BY_HEALER_BEFORE_JOINED:
    'video_call_rejected_by_healer_before_joined',
  VIDEO_CALL_ENDED_BY_HEALER: 'video_call_ended_by_healer',
  VIDEO_CALL_ENDED_BY_CUSTOMER: 'video_call_ended_by_customer',
  VIDEO_CALL_END_ACCEPTED_BY_CUSTOMER: 'video_call_end_accepted_by_customer',
  VIDEO_CALL_END_ACCEPTED_BY_HEALER: 'video_call_end_accepted_by_healer',
  CHAT_ENDED_UNEXPECTEDLY: 'chat_ended_unexpectedly',
}

export const chatType = {
  withHealer: 'chat with healer',
  individualService: 'individual service',
}

export const serviceStatus = {
  completed: 'Completed',
  canceled: 'Canceled',
  disputed: 'Disputed',
}

export const userAvailibilityStatus = {
  AVAILABLE: 'Available',
  BUSY: 'Busy',
  NOT_AVAILABLE: 'Not Available',
}

export const profileStatus = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
}

export const unicodeToHide = new Set()
  .add('1f4a9')
  .add('1f92c')
  .add('1f92e')
  .add('1f922')
  .add('1f5a4')
  .add('1f92f')
  .add('1f921')
  .add('1f644')
  .add('1f612')
  .add('1f925')
  .add('1f92a')
  .add('1f974')
  .add('1f92b')
  .add('1f92c')
  .add('1f618')
  .add('1f60d')
  .add('1f913')
  .add('1f624')
  .add('1f90c')
  .add('1f4a6')
  .add('1f975')
  .add('1f352')
  .add('1f34C')
  .add('1f968')
  .add('1f346')
  .add('1f351')
  .add('1f529')
  .add('1f48b')
  .add('1f595')

export const notificationTypes = {
  DEFAULT_NOTIFICATION: 0,
  CHAT: 1,
  PRODUCT_REJECTED: 2,
  SERVICE_REJECTED: 3,
  PRODUCT_INACTIVED: 4,
  SERVICE_INACTIVED: 5,
  SERVICE_COMPLETED: 6,
  GO_LIVE_GROUP_SERVICE: 7,
  SERVICE_CHAT_STARTED: 8,
  PRODUCT_CONFIRMED: 9,
  PRODUCT_APPROVED: 10,
  SERVICE_APPROVED: 11,
  SERVICE_ADDED: 12,
  PRODUCT_ADDED: 13,
  NEW_HEALER_REGISTERED: 14,
  SERVICE_ORDERED: 15,
  PRODUCT_UPDATED: 16,
  SERVICE_UPDATED: 17,
  PRODUCT_ACTIVED: 18,
  SERVICE_ACTIVED: 19,
}
