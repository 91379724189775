import { AES, enc } from 'crypto-js'
import { enqueueSnackbar } from 'notistack'
import imageCompression from 'browser-image-compression'

export const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export const showError = (error) => {
  if (error?.response?.status === 401) {
    window.location.href = '/login'
    localStorage.clear()
    sessionStorage.clear()
    enqueueSnackbar('An unexpected error occurred', {
      variant: 'error',
    })
  } else if (error.message === 'Network Error') {
    enqueueSnackbar('Please check your network and try again!', {
      variant: 'error',
    })
  } else {
    if (error?.response?.status === 500) {
      enqueueSnackbar('Server error! Please try again', {
        variant: 'error',
      })
    } else if (error?.response?.data?.message) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: 'error',
      })
    } else {
      enqueueSnackbar('An unexpected error occurred', {
        variant: 'error',
      })
    }
  }
}

export const isNumber = (number) => {
  const isNum = /^\d+$/.test(number)

  return isNum
}

export const shortName = (name) => {
  return name
    ? name
        ?.match(/\b(\w)/g)
        ?.slice(0, 2)
        ?.join('')
        ?.toUpperCase()
    : ''
}

export const formatNumberWithFixedValue = (number) => {
  if (number < 0) return 0

  return Math.trunc(Number(number) * 100) / 100
}

export const formatNumberWithTwoPrecision = (number) => {
  if (number < 0) return 0

  return formatNumber(Number(number).toFixed(2))
}

export const formatNumberWithTwoPrecisionNegatives = (number) => {
  return formatNumberNegatives(Number(number).toFixed(2))
}

export const formatNumberNegatives = (number) => {
  if (number >= 10000000)
    number =
      (number / 10000000).toLocaleString('en-IN', {
        maximumFractionDigits: 2,
      }) + ' Cr'
  else if (number >= 100000)
    number =
      (number / 100000).toLocaleString('en-IN', { maximumFractionDigits: 2 }) +
      ' Lac'
  else if (number >= 1000)
    number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return number
}

export const formatNumber = (number) => {
  if (number < 0) return 0

  if (number >= 10000000)
    number =
      (number / 10000000).toLocaleString('en-IN', {
        maximumFractionDigits: 2,
      }) + ' Cr'
  else if (number >= 100000)
    number =
      (number / 100000).toLocaleString('en-IN', { maximumFractionDigits: 2 }) +
      ' Lac'
  else if (number >= 1000)
    number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return number

  // return number > 0
  //   ? number.toLocaleString('en-IN', { maximumFractionDigits: 2 })
  //   : 0
}

export const formatNumberWithOnePrecision = (number) => {
  if (number > 0) {
    const rounded = Math.round(number * 10) / 10
    return parseFloat(rounded.toFixed(1))
  } else {
    return 0
  }
}

export const formatNumberWithPrecision = (number) => {
  return number > 0 ? Math.round((number + Number.EPSILON) * 100) / 100 : 0
}

export const formatNumberWithPrecisionPer = (number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100
}

export const formatNumberWithNoPrecision = (number) => {
  return number > 0 ? number.toFixed(0) : 0
}

export const getPercentage = (number1, number2) => {
  const offPercentage = number2
    ? number2 !== '0'
      ? formatNumberWithPrecision(((number1 - number2) / number1) * 100)
      : 0
    : 0

  return offPercentage + '% Off'
}

export const isUrlValid = (url) => {
  if (typeof url === 'string')
    if (url.indexOf('http') === 0) {
      return true
    } else {
      return false
    }
  return false
}

/**
 * encrypt message
 * @param encryptToText
 * @returns
 */
export const encryptMessage = (encryptToText) => {
  try {
    const encrypted = AES.encrypt(
      encryptToText,
      process.env.REACT_APP_AES_ENC_KEY,
    )

    return encrypted.toString()
  } catch (e) {
    console.error(e)
  }
}

/**
 * decrypt message
 * @param encryptToText
 * @returns
 */
export const decryptMessage = (encryptToText) => {
  try {
    const decrypted = AES.decrypt(
      encryptToText,
      process.env.REACT_APP_AES_ENC_KEY,
    )

    return decrypted.toString(enc.Utf8)
  } catch (e) {
    console.error(e)
  }
}

export const getAspectRatio = (image) => {
  const w = image.naturalWidth
  const h = image.naturalHeight

  let aspectRatio

  if (w > h) {
    aspectRatio = w / h
  } else {
    aspectRatio = h / w
  }

  return aspectRatio
}

export const sortByOrder = (array1, array2) => {
  const map = new Map() // Create a map for efficient lookups

  // Build a map of id to object from array2 for faster access
  for (const item of array2) {
    map.set(item.id, item)
  }

  return array1.sort((a, b) => {
    const item1 = map.get(a.id)
    const item2 = map.get(b.id)

    // Check if both items exist in array2
    if (item1 && item2) {
      // Sort based on their position in array2
      return array2.indexOf(item1) - array2.indexOf(item2)
    } else if (item1) {
      // If item1 exists, put it before non-existent items
      return -1
    } else if (item2) {
      // If item2 exists, put it after non-existent items
      return 1
    }
    // If neither item exists, sort based on their original order
    return a.id - b.id
  })
}

export const checkValues = (a, b, c) => {
  let positiveValues = [a, b, c].filter((value) => value > 0)

  if (positiveValues?.length === 0) {
    return null
  }

  let closestToZero = positiveValues.reduce((prev, curr) =>
    Math.abs(curr) < Math.abs(prev) ? curr : prev,
  )

  return {
    positiveValues: positiveValues,
    closestToZero: closestToZero,
    array: positiveValues.indexOf(closestToZero),
  }
}

export const compressImage = async (file, maxSizeMB) => {
  const options = {
    maxSizeMB: maxSizeMB,
    useWebWorker: true,
    fileType: 'image/png',
  }

  try {
    const compressedFile = await imageCompression(file, options)
    return compressedFile
  } catch (error) {
    console.log(error)
    enqueueSnackbar('Something went wrong', { variant: 'error' })
    return
  }
}

export const isValidPostalCode = (postalCode, countryCode) => {
  let postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/

  switch (countryCode) {
    case 'GB':
      postalCodeRegex =
        /^GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4}$/
      break
    case 'JE':
      postalCodeRegex = /^JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/
      break
    case 'GG':
      postalCodeRegex = /^GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/
      break
    case 'IM':
      postalCodeRegex = /^IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/
      break
    case 'US':
      postalCodeRegex = /^\d{5}([ \-]\d{4})?$/
      break
    case 'CA':
      postalCodeRegex =
        /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/
      break
    case 'DE':
      postalCodeRegex = /^\d{5}$/
      break
    case 'JP':
      postalCodeRegex = /^\d{3}-\d{4}$/
      break
    case 'FR':
      postalCodeRegex = /^\d{2}[ ]?\d{3}$/
      break
    case 'AU':
      postalCodeRegex = /^\d{4}$/
      break
    case 'IT':
      postalCodeRegex = /^\d{5}$/
      break
    case 'CH':
      postalCodeRegex = /^\d{4}$/
      break
    case 'AT':
      postalCodeRegex = /^\d{4}$/
      break
    case 'ES':
      postalCodeRegex = /^\d{5}$/
      break
    case 'NL':
      postalCodeRegex = /^\d{4}[ ]?[A-Z]{2}$/
      break
    case 'BE':
      postalCodeRegex = /^\d{4}$/
      break
    case 'DK':
      postalCodeRegex = /^\d{4}$/
      break
    case 'SE':
      postalCodeRegex = /^\d{3}[ ]?\d{2}$/
      break
    case 'NO':
      postalCodeRegex = /^\d{4}$/
      break
    case 'BR':
      postalCodeRegex = /^\d{5}[\-]?\d{3}$/
      break
    case 'PT':
      postalCodeRegex = /^\d{4}([\-]\d{3})?$/
      break
    case 'FI':
      postalCodeRegex = /^\d{5}$/
      break
    case 'AX':
      postalCodeRegex = /^22\d{3}$/
      break
    case 'KR':
      postalCodeRegex = /^\d{3}[\-]\d{3}$/
      break
    case 'CN':
      postalCodeRegex = /^\d{6}$/
      break
    case 'TW':
      postalCodeRegex = /^\d{3}(\d{2})?$/
      break
    case 'SG':
      postalCodeRegex = /^\d{6}$/
      break
    case 'DZ':
      postalCodeRegex = /^\d{5}$/
      break
    case 'AD':
      postalCodeRegex = /^AD\d{3}$/
      break
    case 'AR':
      postalCodeRegex = /^([A-HJ-NP-Z])?\d{4}([A-Z]{3})?$/
      break
    case 'AM':
      postalCodeRegex = /^(37)?\d{4}$/
      break
    case 'AZ':
      postalCodeRegex = /^\d{4}$/
      break
    case 'BH':
      postalCodeRegex = /^((1[0-2]|[2-9])\d{2})?$/
      break
    case 'BD':
      postalCodeRegex = /^\d{4}$/
      break
    case 'BB':
      postalCodeRegex = /^(BB\d{5})?$/
      break
    case 'BY':
      postalCodeRegex = /^\d{6}$/
      break
    case 'BM':
      postalCodeRegex = /^[A-Z]{2}[ ]?[A-Z0-9]{2}$/
      break
    case 'BA':
      postalCodeRegex = /^\d{5}$/
      break
    case 'IO':
      postalCodeRegex = /^BBND 1ZZ$/
      break
    case 'BN':
      postalCodeRegex = /^[A-Z]{2}[ ]?\d{4}$/
      break
    case 'BG':
      postalCodeRegex = /^\d{4}$/
      break
    case 'KH':
      postalCodeRegex = /^\d{5}$/
      break
    case 'CV':
      postalCodeRegex = /^\d{4}$/
      break
    case 'CL':
      postalCodeRegex = /^\d{7}$/
      break
    case 'CR':
      postalCodeRegex = /^\d{4,5}|\d{3}-\d{4}$/
      break
    case 'HR':
      postalCodeRegex = /^\d{5}$/
      break
    case 'CY':
      postalCodeRegex = /^\d{4}$/
      break
    case 'CZ':
      postalCodeRegex = /^\d{3}[ ]?\d{2}$/
      break
    case 'DO':
      postalCodeRegex = /^\d{5}$/
      break
    case 'EC':
      postalCodeRegex = /^([A-Z]\d{4}[A-Z]|(?:[A-Z]{2})?\d{6})?$/
      break
    case 'EG':
      postalCodeRegex = /^\d{5}$/
      break
    case 'EE':
      postalCodeRegex = /^\d{5}$/
      break
    case 'FO':
      postalCodeRegex = /^\d{3}$/
      break
    case 'GE':
      postalCodeRegex = /^\d{4}$/
      break
    case 'GR':
      postalCodeRegex = /^\d{3}[ ]?\d{2}$/
      break
    case 'GL':
      postalCodeRegex = /^39\d{2}$/
      break
    case 'GT':
      postalCodeRegex = /^\d{5}$/
      break
    case 'HT':
      postalCodeRegex = /^\d{4}$/
      break
    case 'HN':
      postalCodeRegex = /^(?:\d{5})?$/
      break
    case 'HU':
      postalCodeRegex = /^\d{4}$/
      break
    case 'IS':
      postalCodeRegex = /^\d{3}$/
      break
    case 'IN':
      postalCodeRegex = /^\d{6}$/
      break
    case 'ID':
      postalCodeRegex = /^\d{5}$/
      break
    case 'IL':
      postalCodeRegex = /^\d{5}$/
      break
    case 'JO':
      postalCodeRegex = /^\d{5}$/
      break
    case 'KZ':
      postalCodeRegex = /^\d{6}$/
      break
    case 'KE':
      postalCodeRegex = /^\d{5}$/
      break
    case 'KW':
      postalCodeRegex = /^\d{5}$/
      break
    case 'LA':
      postalCodeRegex = /^\d{5}$/
      break
    case 'LV':
      postalCodeRegex = /^\d{4}$/
      break
    case 'LB':
      postalCodeRegex = /^(\d{4}([ ]?\d{4})?)?$/
      break
    case 'LI':
      postalCodeRegex = /^(948[5-9])|(949[0-7])$/
      break
    case 'LT':
      postalCodeRegex = /^\d{5}$/
      break
    case 'LU':
      postalCodeRegex = /^\d{4}$/
      break
    case 'MK':
      postalCodeRegex = /^\d{4}$/
      break
    case 'MY':
      postalCodeRegex = /^\d{5}$/
      break
    case 'MV':
      postalCodeRegex = /^\d{5}$/
      break
    case 'MT':
      postalCodeRegex = /^[A-Z]{3}[ ]?\d{2,4}$/
      break
    case 'MU':
      postalCodeRegex = /^(\d{3}[A-Z]{2}\d{3})?$/
      break
    case 'MX':
      postalCodeRegex = /^\d{5}$/
      break
    case 'MD':
      postalCodeRegex = /^\d{4}$/
      break
    case 'MC':
      postalCodeRegex = /^980\d{2}$/
      break
    case 'MA':
      postalCodeRegex = /^\d{5}$/
      break
    case 'NP':
      postalCodeRegex = /^\d{5}$/
      break
    case 'NZ':
      postalCodeRegex = /^\d{4}$/
      break
    case 'NI':
      postalCodeRegex = /^((\d{4}-)?\d{3}-\d{3}(-\d{1})?)?$/
      break
    case 'NG':
      postalCodeRegex = /^(\d{6})?$/
      break
    case 'OM':
      postalCodeRegex = /^(PC )?\d{3}$/
      break
    case 'PK':
      postalCodeRegex = /^\d{5}$/
      break
    case 'PY':
      postalCodeRegex = /^\d{4}$/
      break
    case 'PH':
      postalCodeRegex = /^\d{4}$/
      break
    case 'PL':
      postalCodeRegex = /^\d{2}-\d{3}$/
      break
    case 'PR':
      postalCodeRegex = /^00[679]\d{2}([ \-]\d{4})?$/
      break
    case 'RO':
      postalCodeRegex = /^\d{6}$/
      break
    case 'RU':
      postalCodeRegex = /^\d{6}$/
      break
    case 'SM':
      postalCodeRegex = /^4789\d$/
      break
    case 'SA':
      postalCodeRegex = /^\d{5}$/
      break
    case 'SN':
      postalCodeRegex = /^\d{5}$/
      break
    case 'SK':
      postalCodeRegex = /^\d{3}[ ]?\d{2}$/
      break
    case 'SI':
      postalCodeRegex = /^\d{4}$/
      break
    case 'ZA':
      postalCodeRegex = /^\d{4}$/
      break
    case 'LK':
      postalCodeRegex = /^\d{5}$/
      break
    case 'TJ':
      postalCodeRegex = /^\d{6}$/
      break
    case 'TH':
      postalCodeRegex = /^\d{5}$/
      break
    case 'TN':
      postalCodeRegex = /^\d{4}$/
      break
    case 'TR':
      postalCodeRegex = /^\d{5}$/
      break
    case 'TM':
      postalCodeRegex = /^\d{6}$/
      break
    case 'UA':
      postalCodeRegex = /^\d{5}$/
      break
    case 'UY':
      postalCodeRegex = /^\d{5}$/
      break
    case 'UZ':
      postalCodeRegex = /^\d{6}$/
      break
    case 'VA':
      postalCodeRegex = /^00120$/
      break
    case 'VE':
      postalCodeRegex = /^\d{4}$/
      break
    case 'ZM':
      postalCodeRegex = /^\d{5}$/
      break
    case 'AS':
      postalCodeRegex = /^96799$/
      break
    case 'CC':
      postalCodeRegex = /^6799$/
      break
    case 'CK':
      postalCodeRegex = /^\d{4}$/
      break
    case 'RS':
      postalCodeRegex = /^\d{6}$/
      break
    case 'ME':
      postalCodeRegex = /^8\d{4}$/
      break
    case 'CS':
      postalCodeRegex = /^\d{5}$/
      break
    case 'YU':
      postalCodeRegex = /^\d{5}$/
      break
    case 'CX':
      postalCodeRegex = /^6798$/
      break
    case 'ET':
      postalCodeRegex = /^\d{4}$/
      break
    case 'FK':
      postalCodeRegex = /^FIQQ 1ZZ$/
      break
    case 'NF':
      postalCodeRegex = /^2899$/
      break
    case 'FM':
      postalCodeRegex = /^(9694[1-4])([ \-]\d{4})?$/
      break
    case 'GF':
      postalCodeRegex = /^9[78]3\d{2}$/
      break
    case 'GN':
      postalCodeRegex = /^\d{3}$/
      break
    case 'GP':
      postalCodeRegex = /^9[78][01]\d{2}$/
      break
    case 'GS':
      postalCodeRegex = /^SIQQ 1ZZ$/
      break
    case 'GU':
      postalCodeRegex = /^969[123]\d([ \-]\d{4})?$/
      break
    case 'GW':
      postalCodeRegex = /^\d{4}$/
      break
    case 'HM':
      postalCodeRegex = /^\d{4}$/
      break
    case 'IQ':
      postalCodeRegex = /^\d{5}$/
      break
    case 'KG':
      postalCodeRegex = /^\d{6}$/
      break
    case 'LR':
      postalCodeRegex = /^\d{4}$/
      break
    case 'LS':
      postalCodeRegex = /^\d{3}$/
      break
    case 'MG':
      postalCodeRegex = /^\d{3}$/
      break
    case 'MH':
      postalCodeRegex = /^969[67]\d([ \-]\d{4})?$/
      break
    case 'MN':
      postalCodeRegex = /^\d{6}$/
      break
    case 'MP':
      postalCodeRegex = /^9695[012]([ \-]\d{4})?$/
      break
    case 'MQ':
      postalCodeRegex = /^9[78]2\d{2}$/
      break
    case 'NC':
      postalCodeRegex = /^988\d{2}$/
      break
    case 'NE':
      postalCodeRegex = /^\d{4}$/
      break
    case 'VI':
      postalCodeRegex = /^008(([0-4]\d)|(5[01]))([ \-]\d{4})?$/
      break
    case 'PF':
      postalCodeRegex = /^987\d{2}$/
      break
    case 'PG':
      postalCodeRegex = /^\d{3}$/
      break
    case 'PM':
      postalCodeRegex = /^9[78]5\d{2}$/
      break
    case 'PN':
      postalCodeRegex = /^PCRN 1ZZ$/
      break
    case 'PW':
      postalCodeRegex = /^96940$/
      break
    case 'RE':
      postalCodeRegex = /^9[78]4\d{2}$/
      break
    case 'SH':
      postalCodeRegex = /^(ASCN|STHL) 1ZZ$/
      break
    case 'SJ':
      postalCodeRegex = /^\d{4}$/
      break
    case 'SO':
      postalCodeRegex = /^\d{5}$/
      break
    case 'SZ':
      postalCodeRegex = /^[HLMS]\d{3}$/
      break
    case 'TC':
      postalCodeRegex = /^TKCA 1ZZ$/
      break
    case 'WF':
      postalCodeRegex = /^986\d{2}$/
      break
    case 'XK':
      postalCodeRegex = /^\d{5}$/
      break
    case 'YT':
      postalCodeRegex = /^976\d{2}$/
      break
    default:
      postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/
  }
  return postalCodeRegex.test(postalCode)
}
