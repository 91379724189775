import React, { lazy, useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { retry } from '../common/commonFunctions'
import { useAuthContext } from '../hooks/useAuthContext'
import axios from 'axios'
import { useLogout } from '../hooks/useLogout'
import { roles } from '../common/constants'
import Loader from '../components/Loader'
import { onMessage } from 'firebase/messaging'
import { messaging } from '../firebase/firebase'
import addNotification from 'react-push-notification'
// import { enqueueSnackbar } from 'notistack'

const Navbar = lazy(() => retry(() => import('../layout/Navbar')))
const FourOhFour = lazy(() => retry(() => import('../pages/FourOhFour')))
const Disclaimer = lazy(() => retry(() => import('../pages/Disclaimer')))
const RefundAndCancellationPolicy = lazy(() =>
  retry(() => import('../pages/RefundAndCancellationPolicy')),
)
const TermsAndConditions = lazy(() =>
  retry(() => import('../pages/TermsAndConditions')),
)
const PrivacyPolicy = lazy(() => retry(() => import('../pages/PrivacyPolicy')))
const HealerSidebar = lazy(() => retry(() => import('../layout/HealerSidebar')))
const VendorSidebar = lazy(() => retry(() => import('../layout/VendorSidebar')))
const AdminSidebar = lazy(() => retry(() => import('../layout/AdminSidebar')))
const ChangePassword = lazy(() =>
  retry(() => import('../pages/vendor/ChangePassword')),
)
const AddProfile = lazy(() =>
  retry(() => import('../pages/customer/profile/AddProfile')),
)
const AddBankingDetails = lazy(() =>
  retry(() => import('../pages/customer/profile/AddBankingDetails')),
)
const AddDocumentDetails = lazy(() =>
  retry(() => import('../pages/customer/profile/AddDocumentDetails')),
)
const AddProfileDetails = lazy(() =>
  retry(() => import('../pages/customer/profile/AddProfileDetails')),
)
const PendingApproval = lazy(() =>
  retry(() => import('../pages/PendingApproval')),
)
const ServerMaintanancePage = lazy(() =>
  retry(() => import('../pages/ServerMaintanancePage')),
)

const Routes = () => {
  const { token } = useAuthContext()
  const { logout } = useLogout()
  const [role, setRole] = useState()
  // const navigate = useNavigate()
  const [status, setStatus] = useState(true)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      const config = {
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Api-Key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${token}`,
        },
      }

      axios
        .get(`${process.env.REACT_APP_API_URL}/users`, config)
        .then((response) => {
          setRole(response.data.data?.user?.role)
        })
        .catch((error) => {
          console.error(error)
          if (error?.response?.data?.statusCode === 401) {
            logout()

            // if (error.response.data.message) {
            //             enqueueSnackbar(error.response.data.message, {
            //               variant: 'error',
            //             })
            //           }
          }
        })
    }
  }, [token])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/status`)
      .then((response) => {
        setStatus(response.data.data.status === 1 ? true : false)
      })
      .catch((error) => {
        setStatus(false)
        // console.log(error)
      })
  }, [])

  useEffect(() => {
    onMessage(messaging, (payload) => {
      try {
        if (!pathname.includes('chats')) {
          addNotification({
            native: true,
            title: payload?.data?.title,
            icon: '/logo192.png',
            message: payload?.data?.body,
            onClick: (e) => {
              localStorage.setItem(
                'chatId',
                JSON.parse(payload?.data?.chat)?.id,
              )
              navigate(JSON.parse(payload?.data?.chat)?.url)
              e.target.close()
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    })
  }, [pathname])

  const routes = useRoutes([
    {
      path: '/healer',
      element: status ? (
        <Navigate replace to="/healer/dashboard" />
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/vendor',
      element: status ? (
        <Navigate replace to="/vendor/dashboard" />
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/admin',
      element: status ? (
        <Navigate replace to="/admin/dashboard" />
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/healer/*',
      element: status ? (
        token ? (
          role !== undefined ? (
            role === roles.Healer ? (
              <HealerSidebar />
            ) : role === roles.Admin ? (
              <Navigate replace to="/admin" />
            ) : role === roles.Vendor ? (
              <Navigate replace to="/vendor" />
            ) : (
              <Navigate replace to="/" />
            )
          ) : (
            <Loader />
          )
        ) : (
          <Navigate replace to="/" />
        )
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/vendor/*',
      element: status ? (
        token ? (
          role !== undefined ? (
            role === roles.Vendor ? (
              <VendorSidebar />
            ) : role === roles.Healer ? (
              <Navigate replace to="/healer" />
            ) : role === roles.Admin ? (
              <Navigate replace to="/admin" />
            ) : (
              <Navigate replace to="/" />
            )
          ) : (
            <Loader />
          )
        ) : (
          <Navigate replace to="/" />
        )
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/admin/*',
      element: status ? (
        token ? (
          role !== undefined ? (
            role === roles.Admin ? (
              <AdminSidebar />
            ) : role === roles.Healer ? (
              <Navigate replace to="/healer" />
            ) : role === roles.Vendor ? (
              <Navigate replace to="/vendor" />
            ) : (
              <Navigate replace to="/" />
            )
          ) : (
            <Loader />
          )
        ) : (
          <Navigate replace to="/" />
        )
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/refund-and-cancellation-policy',
      element: <RefundAndCancellationPolicy />,
    },
    {
      path: '/disclaimer',
      element: <Disclaimer />,
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditions />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/*',
      element: status ? (
        token ? (
          role !== undefined ? (
            role === roles.Customer ? (
              <Navbar />
            ) : (
              <Navigate replace to={`/${role}`} />
            )
          ) : (
            <Loader />
          )
        ) : (
          <Navbar />
        )
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/pending-approval',
      element: status ? (
        !token ? (
          <PendingApproval />
        ) : (
          <Navigate replace to="/login" />
        )
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/vendor/add-profile',
      element: status ? (
        !token ? (
          <AddProfile />
        ) : (
          <Navigate replace to="/login" />
        )
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/healer/add-profile-details',
      element: status ? (
        !token ? (
          <AddProfileDetails />
        ) : (
          <Navigate replace to="/login" />
        )
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/healer/add-banking-details',
      element: status ? (
        !token ? (
          <AddBankingDetails />
        ) : (
          <Navigate replace to="/login" />
        )
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/healer/add-document-details',
      element: status ? (
        !token ? (
          <AddDocumentDetails />
        ) : (
          <Navigate replace to="/login" />
        )
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/vendor/add-banking-details',
      element: status ? (
        !token ? (
          <AddBankingDetails />
        ) : (
          <Navigate replace to="/login" />
        )
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/vendor/add-document-details',
      element: status ? (
        !token ? (
          <AddDocumentDetails />
        ) : (
          <Navigate replace to="/login" />
        )
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/vendor/change-password/:jitToken',
      element: status ? (
        !token ? (
          <ChangePassword />
        ) : (
          <Navigate replace to="/login" />
        )
      ) : (
        <ServerMaintanancePage />
      ),
    },
    {
      path: '/404',
      element: <FourOhFour />,
    },

    // {
    //   path: '/live-streaming',
    //   element: <LiveStreaming />,
    // },
  ])

  return routes
}

export default React.memo(Routes)
