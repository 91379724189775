import PropTypes from 'prop-types'
import { useMemo } from 'react'
// @mui
import { CssBaseline, responsiveFontSizes } from '@mui/material'
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles'
//
import palette from './palette'
import shadows from './shadows'
import typography from './typography'
import GlobalStyles from './globalStyles'
import customShadows from './customShadows'

// ----------------------------------------------------------------------

const ThemeProvider = (props) => {
  const { children } = props

  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [],
  )

  const theme = createTheme(themeOptions)
  theme.components = {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          border: '1.5px solid rgba(255, 134, 0, 0.60)',
          boxShadow: '0px 0px 60.9px 0px rgba(0, 0, 0, 0.10)',
          [theme.breakpoints.up('md')]: {
            padding: '25px',
          },
          [theme.breakpoints.down('md')]: {
            padding: '25px 0px',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#000000',
          fontWeight: 500,
          fontSize: '14px',
          whiteSpace: 'wrap !important',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '13.5px !important',
        },
        root: {
          borderRadius: '12px !important',
        },
        inputMultiline: {
          padding: '0px !important',
          borderRadius: '0 !important',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        input: {
          padding: '0px !important',
        },
        select: {
          paddingTop: '5px !important',
          paddingBottom: '5px !important',
          paddingLeft: '8px !important',
          paddingRight: '24px !important',
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              top: 'calc(50% - .5em)',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: '12px',
          fontSize: '16px',
          fontWeight: 400,
          borderWidth: '0.8px',
        },
        notchedOutline: {
          border: '1px solid #E6E6E6',
          backgroundColor: 'rgba(159, 158, 150, 0.08)',
          borderRadius: '12px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        standard: {
          borderRadius: '12px 0 0 12px',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 15,
          paddingRight: '30px !important',
        },
        icon: {
          top: 'unset',
          marginRight: 4,
        },
        select: {
          '.MuiTypography-subtitle2': {
            overflow: 'hidden',
          },
        },
        // icon: {
        //   top: '30%',
        //   marginRight: '6px',
        // },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          li: {
            wordBreak: 'break-word',
            whiteSpace: 'normal',
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            WebkitTextFillColor: 'unset',
          },
          '&.Mui-active': {
            color: 'white',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          '.MuiIconButton-edgeEnd': {
            backgroundColor: '#FF8600',
            borderRadius: 0,
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          marginTop: '8px !important',
          '&.Mui-completed': {
            fontSize: '12px',
            fontWeight: 500,
            color: '#FF8600',
          },
          '&.Mui-active': {
            fontSize: '12px',
            fontWeight: 500,
            color: '#FF8600',
          },
          '&.Mui-disabled': {
            fontSize: '12px',
            fontWeight: 500,
            color: '#011B36',
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          top: '16px',
          '&.Mui-completed .MuiStepConnector-line': {
            borderColor: '#FF8600',
          },
        },
        line: {
          borderColor: '#011B36',
          borderTopWidth: '2px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          color: '#000000',
          boxShadow: 'none',
          borderBottom: '1.5px solid #FF8600',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '12px',
          padding: '15px 18px',
          // maxWidth: '410px',
        },
        startIcon: {
          [theme.breakpoints.down('md')]: {
            margin: '0',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        filled: {
          height: '27px',
          fontSize: '14px',
          fontWeight: 500,
          color: 'white',
          backgroundColor: '#FF8600',
          borderRadius: '6px',
        },
        label: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor:
                  theme.palette.mode === 'light'
                    ? '#FF8600'
                    : theme.palette.grey[600],
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#FF8600',
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color:
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor:
              theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
              duration: 500,
            }),
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '4px !important',
        },
        popupIndicator: {
          marginRight: '2px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 500,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '10px',
          border: '0.5px solid #011B3614',
          boxShadow: '0px 0px 10px 3px #0000000D',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: '1.5px solid #FF860099',
          boxShadow: '0px 0px 60.900001525878906px 0px #0000001A',
          borderRadius: '20px',
          paddingBottom: '10px',
        },
        root: {
          zIndex: 1000,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingLeft: '22px',
          paddingRight: '22px',
        },
      },
    },
    MuiOtpInput: {
      styleOverrides: {
        TextField: { backgroundColor: 'red' },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FF8600',
          fontSize: '14px',
          fontWeight: 500,
          width: '46px',
          height: '46px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: '#FF8600',
          border: '1.5px solid #FF860099',
          boxShadow: '0px 0px 60.900001525878906px 0px #0000001A',
          marginTop: '0px !important',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: '20px',
          boxShadow: '0px 5px 16px 0px #011B360F',
          borderRadius: '20px',
          border: ' 1px solid rgba(0, 0, 0, 0.07)',
          color: '#000000',
          fontSize: 22,
          fontWeight: 500,
          padding: '20px 0',
          transition: 'all 0.3s',
          ':first-of-type': {
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          },
          ':last-of-type': {
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
          },
          '::before': {
            backgroundColor: 'transparent',
            height: 0,
          },
          '&.Mui-expanded': {
            border: '2px solid #FF8600',
            transition: 'all 0.3s',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          color: '#000000',
          fontSize: 18,
          fontWeight: 400,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          columnGap: '10px',
        },
        content: {
          margin: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
        expandIconWrapper: {
          minWidth: '50px ',
          minHeight: '50px ',
          backgroundColor: theme.palette.common.white,
          borderRadius: '50%',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #0000000A',
          boxShadow: '0px 5px 16px 0px #080F340F',
          transform: 'rotate(270deg)',
          transition: 'all 0.3s',
          [theme.breakpoints.down('md')]: {
            minWidth: '35px ',
            minHeight: '35px ',
          },
          svg: {
            path: {
              stroke: theme.palette.primary.main,
            },
          },
          '&.Mui-expanded': {
            backgroundColor: theme.palette.primary.main,
            transform: 'rotate(360deg)',
            transition: 'all 0.3s',
            svg: {
              path: {
                stroke: theme.palette.common.white,
              },
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          border: '1.5px solid #FF8600',
          color: '#FF8600',
          fontWeight: 500,
          '&.Mui-selected': {
            backgroundColor: '#FF8600',
            color: '#FFF',
            ':hover': {
              backgroundColor: '#FF8600',
              color: '#FFF',
            },
          },
          ':hover': {
            backgroundColor: '#FF8600',
            color: '#FFF',
          },
        },
        ellipsis: {
          color: '#FF8600',
        },
        previousNext: {
          border: '1.5px solid #FF8600',
          color: '#FF8600',
          ':hover': {
            backgroundColor: '#FF8600',
            color: '#FFF',
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          fontSize: '14px',
          paddingRight: '42px',
        },
      },
    },
    // MuiPaginationItem: {
    //   styleOverrides: {
    //     previousNext: {
    //       border: 'none',
    //       marginLeft: '16px',
    //       marginRight: '16px',
    //     },
    //     root: {
    //       fontSize: '8.438px',
    //       fontWeight: 700,
    //       '&.Mui-selected': {
    //         backgroundColor: theme.palette.primary.main,
    //         border: '0.469px solid #8C7DFF',
    //         color: theme.palette.common.white,
    //       },
    //     },
    //     page: {
    //       color: '#BCBCBC',
    //       borderRadius: '2.813px',
    //       border: '0.469px solid #BCBCBC',
    //     },
    //   },
    // },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          zIndex: 1000,
        },
      },
    },
    MuiDigitalClock: {
      styleOverrides: {
        item: {
          justifyContent: 'center',
          [theme.breakpoints.down('sm')]: {
            minHeight: '40px',
          },
        },
      },
    },
  }

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}

export default ThemeProvider
