import React, { Suspense, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ThemeProvider from './theme'
import ScrollToTop from './components/ScrollToTop'
import { styled } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import Loader from './components/Loader'
import Routes from './routes/Routes'
import { HelmetProvider } from 'react-helmet-async'
import './css/App.css'
import { AuthContextProvider } from './context/AuthContext'
import { requestPermission } from './firebase/firebase'
import { roles } from './common/constants'

// alert notification
const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.notistack-MuiContent-success {
    background-color: #ff8600;
    color: #ffffff;
  }
  ,
  &.notistack-MuiContent-error {
    background-color: #ff1e1e;
  }
  ,
  &.notistack-MuiContent {
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
      0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    font-weight: 500;
    border-radius: 12px;
  }
`

const App = () => {
  useEffect(() => {
    if (
      sessionStorage.getItem('role') === roles.Healer
        ? sessionStorage.getItem('token')
        : localStorage.getItem('token')
    ) {
      requestPermission()
    }
  }, [
    sessionStorage.getItem('role') === roles.Healer
      ? sessionStorage.getItem('token')
      : localStorage.getItem('token'),
  ])

  return (
    <AuthContextProvider>
      <BrowserRouter>
        <HelmetProvider>
          <ThemeProvider>
            <ScrollToTop />
            <StyledSnackbarProvider
              // iconVariant={{
              //   success: <LazyLoadImage src={success} alt="success" />,
              //   error: <LazyLoadImage src={error} alt="error" />,
              //   warning: '⚠️',
              //   info: 'ℹ️',
              // }}
              hideIconVariant
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Suspense fallback={<Loader />}>
                <Routes />
              </Suspense>
            </StyledSnackbarProvider>
          </ThemeProvider>
        </HelmetProvider>
      </BrowserRouter>
    </AuthContextProvider>
  )
}

export default App
