import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if ('serviceWorker' in navigator) {
  // Register the new service worker
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log(
        'New Service Worker registered with scope:',
        registration.scope,
      )
    })
    .catch((error) => {
      console.log('Service Worker registration failed:', error)
    })

  // Listen for messages from the service worker
  navigator.serviceWorker.addEventListener('message', (event) => {
    const { action, key, value } = event.data

    if (action === 'set') {
      localStorage.setItem(key, value)
    }

    if (action === 'navigate' && value) {
      window.location.href = value
    }
  })
}
