import { useSnackbar } from 'notistack'
import { useAuthContext } from './useAuthContext'
import axios from 'axios'
import { useState } from 'react'

export const useLogout = () => {
  const [isLoading, setIsLoading] = useState(null)
  const { dispatch } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()
  const { token } = useAuthContext()

  const logout = async () => {
    setIsLoading(true)

    const config = {
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Api-Key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${token}`,
      },
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/logout`, {}, config)
      .then((response) => {
        if (response.data) {
          // remove token from storage
          localStorage.clear()
          sessionStorage.clear()

          // dispatch logout action
          dispatch({ type: 'LOGOUT' })

          // update loading state
          setIsLoading(false)

          if (response.data.message) {
            enqueueSnackbar(response.data.message, {
              variant: 'success',
            })
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error(error)
        setIsLoading(false)

        if (error?.response?.data?.statusCode === 401) {
          // remove token from storage
          localStorage.clear()
          sessionStorage.clear()

          // dispatch logout action
          dispatch({ type: 'LOGOUT' })
        } else if (error?.response?.data?.message) {
          enqueueSnackbar(error?.response?.data?.message, {
            variant: 'error',
          })
        }
      })
  }

  return { logout, isLoading }
}
