import axios from 'axios'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'
import firebaseConfig from './firebase.config.json'
import { roles } from '../common/constants'

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app)

export const requestPermission = async () => {
  console.log('Requesting permission...')

  try {
    const permission = await Notification.requestPermission()

    if (permission === 'granted') {
      console.log('Notification permission granted.')
      getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      })
        .then((currentToken) => {
          if (currentToken) {
            try {
              const config = {
                withCredentials: true,
                headers: {
                  Authorization: `Bearer ${
                    sessionStorage.getItem('role') === roles.Healer
                      ? sessionStorage.getItem('token')
                      : localStorage.getItem('token')
                  }`,
                },
              }

              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/device-tokens`,
                  {
                    deviceId:
                      'web' + Math.random().toString(20).substring(2, 14),
                    deviceType: 'Web',
                    token: currentToken,
                  },
                  config,
                )
                .catch(() => {})
            } catch (error) {
              console.log(error)
            }
          } else {
            console.log(
              'No registration token available. Request permission to generate one.',
            )
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
        })
    }
  } catch (error) {
    console.log(error)
  }
}
